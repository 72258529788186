<template>
  <link href="https://fonts.cdnfonts.com/css/barlow" rel="stylesheet" />
  <div class="hello">
    <div style="display:flex; justify-content: center;">
      <!--h2 class="main-header">Assistente Hygea</h2-->
      <!--img @click="golink" class="icon main-icon" src="@/assets/img/logo-hygea.png" style="padding: 0 20px; border-right: solid 3px white;" alt="" /-->
      
      <img class="icon main-icon" src="@/assets/img/widelabs-main.svg" alt="" />
      <img class="icon main-icon" src="@/assets/img/widelabs-white.svg" alt="" />
      <img class="icon main-icon" src="@/assets/img/mprs.svg" alt="" />
      <!--img class="icon sup-dir-icon" src="@/assets/img/wide-line.svg" alt="" /-->
    </div>


    <div class="main-chatbox">
      <input placeholder="Digite sua dúvida ou mensagem." type="text" class="search-input" @keyup.enter="onEnter"
        v-model="textBox" />
      <!--img @click="onEnter" class="icon search-icon" src="@/assets/img/lupa.svg" alt="" /-->
      <span class="sendBtn" @click="onEnter">Enviar</span>
    </div>
    <div class="chatbox-wrapper">
      <span class="loading" v-if="isLoading">Aguarde enquanto sua mensagem é processada.</span>
      <div id="chatbox" class="main-chatmessage">
        <div v-for="(msg, index) in msgs" :key="index">
          <div align="left" class="box user-box">
            <!--img class="icon user-icon" src="@/assets/img/user-icon.jpg" alt="" /-->
            <div class="user-text">
              <span> {{ msg.user }}</span>
            </div>
          </div>
          <div v-for="(document, index) in msg.bot.documents" :key="index" align="left" class="box bot-box">
            <div class="user-icon-wrapper">
              <img class="icon user-icon" src="@/assets/img/agape.jpg" alt="" />
            </div>
            <div>
              <span v-html="document.abstract"> </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--img class="icon inf-esq-icon" src="@/assets/img/inf-esq.svg" alt="" /-->
  </div>
</template>

<script>
import backgroundImage from '@/assets/img/wide-background.png';

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      botAnswer: "Justo",
      msgs: [],
      textBox: "",
      response: [],
      isLoading: false,
      timestamp:0,
      backgroundImage: backgroundImage,
    };
  },
  computed: {
  },
  mounted(){
    this.timestamp=Date.now()
  },
  methods: {
    redirect(link) {
      window.open(link, "_blank");
    },
    convertHttpsLinksToAnchors(text) {
      // Regular expression to match links that start with "https" and end with "htm" or "html"
      const linkRegex = /https:\/\/[\w\-._~:/?#[\]@!$&'()*+,;=]*\.htm[l]?/g;

      // Replace matched links with anchor tags
      return text.replace(linkRegex, function (match) {
        return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
      });
    },
    markdownToHTML(markdown) {
      // Replace Markdown headers (h1 - h6)
      markdown = markdown.replace(/(#+)(.*)/g, function (match, hashes, text) {
        var level = hashes.length;
        return "<h" + level + ">" + text.trim() + "</h" + level + ">";
      });

      // Replace Markdown bold (**text** or __text__)
      markdown = markdown.replace(
        /\*\*(.*?)\*\*|__(.*?)__/g,
        "<strong>$1$2</strong>"
      );

      // Replace Markdown italic (*text* or _text_)
      markdown = markdown.replace(/\*(.*?)\*|_(.*?)_/g, "<em>$1$2</em>");

      // Replace Markdown inline code (`code`)
      markdown = markdown.replace(/`(.*?)`/g, "<code>$1</code>");

      // Replace Markdown links ([text](url))
      markdown = markdown.replace(
        /\[([^[]+)\]\(([^)]+)\)/g,
        '<a href="$2">$1</a>'
      );

      // Replace Markdown images (![alt text](image url))
      markdown = markdown.replace(
        /!\[([^[]*)\]\(([^)]+)\)/g,
        '<img src="$2" alt="$1">'
      );

      // Replace Markdown line breaks (two spaces at the end of a line)
      markdown = markdown.replace(/ {2}\n/g, "<br />\n");

      return this.convertHttpsLinksToAnchors(markdown);
    },
    golink(){
      window.open("https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/grjqomg8q9fl/b/brb-retrieval-files-bucket-20240626-1325/o/Circular Bacen 3682.pdf",'_blank')
    },
    onEnter() {
      //https://backend-u3vm4h6pda-uc.a.run.app/query user_query
      if ((this.isLoading == true)) {
        return false;
      }
      const axios = require("axios");
      this.isLoading = true;
      axios
        .post(
          "https://adocao-v1-951989781070.us-central1.run.app/poc-adocao-api",
          {
            timestamp:this.timestamp,
            text: this.textBox,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.response = [response];
          let document = {
            name: "Relatório de produtividade Etanol outubro 2023",
            abstract: "",
            link: "https://widelabs.com.br",
            tags: ["1", "2", "3", "4"],
          };
          this.msgs = [
            {
              bot: {
                documents: [],
              },
              user: this.textBox,
            },
            ...this.msgs,
          ];
          this.response.forEach((element) => {
            let mkdText = this.markdownToHTML(element.data["text"]);
            document.abstract = mkdText;
            this.msgs[0].bot.documents.push(document);
          });
          this.textBox = "";
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
      return true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Montserrat", Sans-serif;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #01C5AD;
}

i {
  cursor: pointer;
  color: #4ac0de;
}

.main-header {
  color: #ffffff;
  margin-top: 10vh;
}

.hello {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-image: "";
  overflow: hidden;
}
.loading{
  color: #01C5AD;
  font-weight: bold;
  margin: 2vh 0;
}
.sup-dir-icon {
  position: absolute;
  width: 50vw;
  right: 10vw;
  margin: 0px !important;
}

.inf-esq-icon {
  position: absolute;
  width: 10vw;
  left: 0px;
  bottom: 0px;
  z-index: 0;
}

.main-chatbox {
  position: absolute;
  top: 18vh;
  left: 5vw;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 24px;
}

.chatbox-wrapper {
  position: absolute;
  top: 30vh;
  left: 5vw;
  width: 88vw;
  height: 63vh;
  background-color: #f5f5f5;
  border-radius: 24px;
  z-index: 1;
  padding: 2vh 1vw;
}

.main-chatmessage {
  width: 88.5vw;
  max-height: 63vh;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 24px;
}

.tags {
  padding: 10px;
  margin: 5px 10px;
  border-radius: 24px;
  background-color: #0B385B;
  color: white;
}

.box {
  background-color: #D9D9D9;
  border: solid 1px #0B385B;
  margin: 1vh 1vw;
  padding: 15px;
  border-radius: 24px;
}

.user-box {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.bot-box {
  display: flex;
  align-items: stretch;
  background-color: #01c5ae50;
}

.icon {
  margin-right: 2vw;
}

.user-icon-wrapper {
  display: flex;
  align-items: flex-start;
}

.user-icon {
  width: 3vw;
  border-radius: 2vw;
}

.search-input {
  width: 90vw;
  border: 0px;
  margin-left: 20px;
  background-color: #f5f5f5;
  height: 5vh;
  font-size: 18px;
}

.search-input:focus{
  outline: none;
}
.search-input::placeholder{
  color: #173557;
}

.main-icon {
  margin-top: 3vh;
  height: 10vh;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
}

.search-icon {
  height: 3vh;
  width: 3vh;
  margin: 2vh;
}

.document-name {
  color: #01C5AD;
  font-weight: bolder;
}

.sendBtn{
  padding: 10px;
  margin: 1vw;
  background-color:#0B385B;
  color: #fff;
  border-radius: 25px;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #2c3e50;
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2c3e50;
}

::-webkit-scrollbar-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  .search-input {
    font-size: 14px;
  }
  .loading{
    font-size: 14px;
  }
  .user-icon {
    width: 5vh;
    border-radius: 3vh;
    margin-right: 2vh;
  }
  .main-icon {
    margin-top: 5vh;
    height: 5vh;
    margin-right: 0;
  }
  .main-chatbox {
    top: 15vh;
  }
  .chatbox-wrapper{
    top: 25vh;
  }

  .main-chatmessage, .chatbox-wrapper{
    height: 67vh;
  }
}
</style>
